import QRCode from "react-qr-code";
import React, { useRef } from "react";
import "../Login_Register/Login.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import didSlice from "../../reducers/didRedcuer";
import behtelIcon from "../../Images/BG-Images/bethel-new-logo.png"
import { useEffect, useState } from "react";
import WalletAddressSlice from "../../reducers/WalletAddressSlice";
import robot from "../../Images/BG-Images/robot.png"
import $u from "../../utils/$u.js";
import "./../../index.css";
import Scene from "../3D/BethelLogo3D";
import { Canvas, useFrame } from "@react-three/fiber";
import { useGLTF, OrbitControls, Environment, Effects, Sky, Stars, useSelect } from '@react-three/drei';
import { Vector3 } from 'three';
import { Bloom, DepthOfField, EffectComposer, Noise } from "@react-three/postprocessing";
import Version_login from "./Version2.0_logIn.jsx";
const { ethers } = require("ethers");
const wc = require("../../witness/wintess_calcutlator.js");
const snarkjs = require("snarkjs");

const version = process.env.REACT_APP_BETHEL_VERSION


function CameraAnimation({ camera, onAnimationComplete }) {
  const startPosition = new Vector3(10, 0, 0);
  const endPosition = new Vector3(0, 0, 9);
  const duration = 7;
  const [animationComplete, setAnimationComplete] = useState(false);


  useFrame(({ clock }) => {
    try {
      const elapsedTime = clock.getElapsedTime();
      const lerpFactor = Math.min(elapsedTime / duration, 1);
      // camera.position.lerpVectors(startPosition, endPosition, lerpFactor);
      // camera.lookAt(0, 0, 0);

      if (lerpFactor === 1) {
        setAnimationComplete(true);
      }
    } catch (error) {
      console.log(error)
    }

  });

  useEffect(() => {
    if (animationComplete) {
      onAnimationComplete();
    }
  }, [animationComplete, onAnimationComplete]);

  return null;
}

function Login({ path }) {
  const cameraRef = useRef();
  const controlsRef = useRef();
  const [pos, setPos] = useState(false)
  const [scale, setScale] = useState(false)

  // chage video speed on scale 
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      // Adjust playback speed based on the `scale` state
      videoRef.current.playbackRate = scale ? 3 : 1; // 0.5x speed when scaled, 1x otherwise
    }
  }, [scale]);

  useEffect(() => {
    if (cameraRef.current) {
      cameraRef.current.position.set(0, 2, 9);
    }
  }, []);

  const handleAnimationComplete = () => {
    if (controlsRef.current) {
      controlsRef.current.enabled = true; // Enable controls after animation
    }
    setPos(true)
  };

  useEffect(() => {
    if (controlsRef.current) {
      controlsRef.current.enabled = false; // Disable controls during animation
    }
  }, []);


  const Navigate = useNavigate();
  const dispatch = useDispatch();

  // for the verification funcs
  const [qrCodeData, setQrCodeData] = useState();
  const [onVerificationResult, setOnverificationResult] = useState(false);
  const [signUpQRData, setSignUpQRData] = useState();
  const [serverErrorFetch, setServerErrorFetch] = useState(false);

  const [sectionHide, setSectionHide] = useState(true);
  // show the Sign in content
  const [showSignIn, setShowSignIn] = useState(false);

  // smart contract actions data
  const fileStorageAddress = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS; // contract address
  const ChainId = process.env.REACT_APP_BLOCK_CHAIN_ID
  const fileStorageJSON = require("../../json/FileStorage.json");
  const fileStorageABI = fileStorageJSON.abi;
  const fileStorageInterface = new ethers.utils.Interface(fileStorageABI);

  // cehck auth validation did check
  const [authWalletAddress, setAuthWalletAddress] = useState(null)  // check already registered 
  const [toggleAuthWarning, setToggleAuthWarning] = useState(true)  // check already registered 

  const checkDidValidation = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const contract = new ethers.Contract(fileStorageAddress, fileStorageABI, signer)
    const wallet_address = await signer.getAddress()

    const res = await contract.getAdressList(did)
    setLinkWallets(res)

    const isUserHere = res.includes(wallet_address)
    if (isUserHere === true) {
      setLinkwalletstatus(true)
      QRRtoggle2()
      setUserStatus((prev) => ({ ...prev, state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '', fail: false, failmsg: "" }))
    }
    return isUserHere

  }

  const auth = async () => {
    try {
      const authRequest = await fetch(
        process.env.REACT_APP_BACKEND_URL + "sign-in"
      );
      setQrCodeData(await authRequest.json());
      const sessionID = authRequest.headers.get("x-id");

      dispatch(WalletAddressSlice.actions.saveWalletAddress(sessionID));

      const interval = setInterval(async () => {
        try {
          const sessionResponse = await fetch(
            process.env.REACT_APP_BACKEND_URL + "status?sessionId=" + sessionID
          );

          if (sessionResponse.status === 200) {

            const did = await sessionResponse.json();
            dispatch(didSlice.actions.didStore(did.id));

            // add login points fn 
            addLoginPoints(did)

            setTimeout(() => {
              Navigate("/dashboard");
            }, 1000);
            clearInterval(interval);
          }

          if (sessionResponse.rejected) {
          }
          if (sessionResponse.status === 102) {
          }
        } catch (e) {
          console.log("err->", e);
        }
      }, 3000);
    } catch (error) {
      setServerErrorFetch(true);
    }
  };

  // if ref here redirect to register menu
  useEffect(() => {
    if (path !== "") {
      SignInQR()
    }
  }, []);

  // QR open and Close toggle
  const [QRtoggle, setQRtoggle] = useState(false);
  // const [QRtoggle2 , setQRtoggle2] = useState(false)
  const [error, setError] = useState("");
  const [toggleWarning, setToggleWarning] = useState(false) //for toggling user adding warning 

  const [did, setDid] = useState(null);

  const [metamaskWarning, setMetamaskWarning] = useState(false) //meta mask warning msg show and hide 

  // linked wallet addresses
  const [linkedWallets, setLinkWallets] = useState(null)
  const [linkwalletstatus, setLinkwalletstatus] = useState(false)
  const [walletaddressvalidation, setWalletaddressvalidation] = useState(false)

  const [userStatus, setUserStatus] = useState({ state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '', QRData: false, fail: false, failmsg: '' })


  // handle did submit function  ---->
  const handleDidSubmit = async (e) => {
    e.preventDefault();

    if (!did) {
      setError("Did is Required!");
      return;
    }
    setToggleWarning(false)

    setSubmitMsg("Loading");
    setError("");
    loadQRData() //load qr data fn 
    setDid("");

  };

  // Sign up qr toggle
  const QRRtoggle2 = () => {
    setSectionHide(true);
    setShowSignIn(false);
    setDid(null);
    setError("");
    setLoginBtnShow(true);
    setRegisterBtnShow(true);
    setDisableRegisterBtn(true);
    setShowSubmitRegBtn(false);
    setShowRegenDiv(false)
    setRegen(false)
  };

  // close meta mask warning msg
  const closeMetaMaskWarning = () => {
    setMetamaskWarning(false)
  }


  // check wallet address is have a did 
  const checkWalletAddress = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const contract = new ethers.Contract(fileStorageAddress, fileStorageABI, signer)

    const res = await contract.getUserDid()
    setWalletaddressvalidation(res)

    return res

  }


  // add user process
  // add user fn for block chanin
  const addUser = async () => {

    //check did is already registers 
    const userCheck = await checkDidValidation()

    const walletCheck = await checkWalletAddress()

    if (walletCheck === true) {
      QRRtoggle2()
      setUserStatus((prev) => ({ ...prev, state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '', fail: false, failmsg: "" }))
      return
    }

    if (userCheck === true) {
      return;
    }

    // check is QR data is loaded or not
    const isQRloaded = await loadQRData()

    if (isQRloaded === false) {
      setUserStatus((prev) => ({ ...prev, state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '', fail: true, failmsg: "QR loading Fail please try again" }))
      QRRtoggle2()
      setTimeout(() => {
        setUserStatus((prev) => ({ fail: false, failmsg: '' }))
      }, 3000);//if fails the actions
      return
    }

    const claim = isQRloaded.claim

    setUserStatus((prev) => ({ ...prev, state: true, state1: true, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '', fail: false, failmsg: "User adding in progress ..." }))


    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const accounts = await provider.send('eth_requestAccounts', []);

    const acc = accounts[0]


    const secret = ethers.BigNumber.from(
      ethers.utils.randomBytes(32)
    ).toString();
    const nullifier = ethers.BigNumber.from(
      ethers.utils.randomBytes(32)
    ).toString();

    const input = {
      secret: $u.BN256ToBin(secret).split(""),
      nullifier: $u.BN256ToBin(nullifier).split(""),
    };
    const jsonContent = JSON.stringify(input, null, 4);
    var res = await fetch("/addUser.wasm");
    var buffer = await res.arrayBuffer();
    var adduserWC = await wc(buffer);

    const r = await adduserWC.calculateWitness(input, 0);

    const commitment = r[1];
    const nullifierHash = r[2];
    const value = ethers.BigNumber.from("100000000000000000").toHexString();

    const resAddUser = await fetch(process.env.REACT_APP_BACKEND_URL + "add-user", {
      method: "POST",
      body: JSON.stringify({
        "Commitment": commitment.toString(),
        "Did": did,
        "NullifierHash": nullifierHash.toString(),
        "Owner": acc
      })
    })

    setUserStatus((prev) => ({ ...prev, state: true, state1: true, state2: true, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '', fail: false, failmsg: "User adding in progress ..." }))


    if (resAddUser.status === 200) {
      const add_user_data = await resAddUser.json()
      try {
        const proofElements = {
          nullifierHash: `${nullifierHash}`,
          secret: secret,
          nullifier: nullifier,
          commitment: `${commitment}`,
          txHash: add_user_data.TXHash,
        };
        const proof_element = btoa(JSON.stringify(proofElements));


        setUserStatus((prev) => ({ ...prev, state: true, state1: true, state2: true, state3: true, state4: false, state5: false, state6: false, complete: false, msg: '', fail: false, failmsg: "User adding in progress ..." }))
        // run verification process function
        verifyUser(proof_element, acc, provider, claim)



      } catch (e) {
        console.log(e);
        setUserStatus((prev) => ({ ...prev, state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '' })) //if fails the actions
        QRRtoggle2()
        setUserStatus((prev) => ({ ...prev, fail: true, failmsg: "User Adding was failed. Please try again" }))
        setTimeout(() => {
          setUserStatus((prev) => ({ ...prev, fail: false, failmsg: "" }))
        }, 3000);

      }
    } else {
      setUserStatus((prev) => ({ ...prev, state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '' })) //if fails the actions
      QRRtoggle2()
      setUserStatus((prev) => ({ ...prev, fail: true, failmsg: "User Adding was failed. Please try again" }))
      setTimeout(() => {
        setUserStatus((prev) => ({ ...prev, fail: false, failmsg: "" }))
      }, 3000);
    }

  };

  // state for time out 
  const [timeLeft, setTimeLeft] = useState(120)

  // set time out function for timout QR
  const timeCountDown = () => {
    const interval = setInterval(() => {
      setTimeLeft(prevTimeLeft => {
        if (prevTimeLeft === 1) {
          clearInterval(interval);
          setTimeLeft(120)
          QRRtoggle2();
        }
        return prevTimeLeft - 1;
      });
    }, 1000);
  };


  // set did registerd value
  const [registered, setRegistered] = useState(false)

  // get path
  const refPath = useSelector((state) => state.refLinkReducer)

  // load qr data into sign up QR
  const loadQRData = async () => {
    try {
      const QRdata = await fetch(process.env.REACT_APP_BACKEND_URL + `sign-up?refID=${refPath && refPath.RefLink}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "DID": did,
          "First": "",
          "Last": "",
          "Email": "",

        }),
      });

      if (QRdata.status === 201) {
        setSectionHide(false);
        const claim = await QRdata.json()
        setSignUpQRData(claim.claim);
        timeCountDown()
        setSubmitMsg("Submit");

      }

      if (QRdata.status === 200) {
        setSectionHide(false);
        setRegistered(true)

        // regenaration msg
        setRegen(true)

        setTimeout(() => {
          setRegistered(false)
        }, 5000);
        setSubmitMsg("Submit");



      }
      // setIsLoadingCreateID(false)
    } catch (error) {
      console.log(error);
      setSectionHide(false);
      setSubmitMsg("Error");
    }
  }

  // verify user
  const verifyUser = async (proof_element, acc, provider, claim) => {
    try {
      setUserStatus((prev) => ({ ...prev, state1: true, state2: true, state3: true, state4: true, msg: "User Verifying in progress" }))

      const proofElements = JSON.parse(atob(proof_element));

      const receipt = await window.ethereum.request({
        method: "eth_getTransactionReceipt",
        params: [proofElements.txHash],
      });
      if (!receipt) {
        throw "empty-receipt";
      }

      const log = receipt.logs[1];

      const decodedData = await fileStorageInterface.decodeEventLog(
        "UserCollected",
        log.data,
        log.topics
      );

      const SnarkJS = window["snarkjs"];

      const proofInput = {
        root: $u.BNToDecimal(decodedData.useroot),
        nullifierHash: proofElements.nullifierHash,
        recipient: $u.BNToDecimal(acc),
        secret: $u.BN256ToBin(proofElements.secret).split(""),
        nullifier: $u.BN256ToBin(proofElements.nullifier).split(""),
        hashPairings: decodedData.userHashPairings.map((n) =>
          $u.BNToDecimal(n)
        ),
        hashDirections: decodedData.userPairDirection,
      };

      const { proof, publicSignals } = await snarkjs.groth16.fullProve(
        proofInput,
        "verifyUser.wasm",
        "setup_final.zkey"
      );

      const providers = new ethers.providers.Web3Provider(window.ethereum)
      const accounts = await providers.send('eth_requestAccounts', []);

      const callInputs = [
        proof.pi_a.slice(0, 2).map($u.BN256ToHex),
        proof.pi_b
          .slice(0, 2)
          .map((row) => $u.reverseCoordinate(row.map($u.BN256ToHex))),
        proof.pi_c.slice(0, 2).map($u.BN256ToHex),
        publicSignals.slice(0, 2).map($u.BN256ToHex),
        did
      ];

      const resUserVerify = await fetch(process.env.REACT_APP_BACKEND_URL + "verify-user", {
        method: "POST",
        body: JSON.stringify(
          {
            "A": JSON.stringify(proof.pi_a.slice(0, 2).map($u.BN256ToHex)),
            "B": JSON.stringify(proof.pi_b
              .slice(0, 2)
              .map((row) => $u.reverseCoordinate(row.map($u.BN256ToHex)))),
            "C": JSON.stringify(proof.pi_c.slice(0, 2).map($u.BN256ToHex)),
            "Inputs": JSON.stringify(publicSignals.slice(0, 2).map($u.BN256ToHex)),
            "Did": did,
            "Owner": accounts[0]
          }
        )
      })

      setUserStatus((prev) => ({ ...prev, state1: true, state2: true, state3: true, state4: true, state5: true, msg: "User Verifying in progress" }))


      if (resUserVerify.status === 200) {
        const tx_hash = await resUserVerify.json()

        provider
          .waitForTransaction(tx_hash.TXHash)
          .then((_receipt) => {
            if (_receipt.status === 0) {
              console.log("Transaction failed.");
              QRRtoggle2()
              setUserStatus((prev) => ({ ...prev, fail: true, failmsg: "Verify user was failed. Please try again" }))
              setTimeout(() => {
                setUserStatus((prev) => ({ ...prev, fail: false, failmsg: "" }))
              }, 3000);
            } else {
              console.log("Transaction successful. yeahhh");
              setSignUpQRData(claim)
              setUserStatus((prev) => ({ ...prev, state1: true, state2: true, state3: true, state6: true, complete: true, msg: "User Added Complete" }))


              setTimeout(() => {
                setUserStatus((prev) => ({ ...prev, state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '' })) //if fails the actions 

              }, 3000);
            }
          })
      }
      else {
        console.log("ts is fail ")
        QRRtoggle2()
        setUserStatus((prev) => ({ ...prev, fail: true, failmsg: "User Adding was failed. Please try again" }))
        setTimeout(() => {
          setUserStatus((prev) => ({ ...prev, fail: false, failmsg: "" }))
        }, 3000);
        setUserStatus((prev) => ({ ...prev, state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '' })) //if fails the actions
      }

      //   });
    } catch (error) {
      QRRtoggle2()
      setUserStatus((prev) => ({ ...prev, fail: true, failmsg: "User Adding was failed. Please try again" }))
      setTimeout(() => {
        setUserStatus((prev) => ({ ...prev, fail: false, failmsg: "" }))
      }, 3000);
      console.log("Errorddd:", error);
      setUserStatus((prev) => ({ ...prev, state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '' })) //if fails the actions
    }
  };

  const SignInQR = async () => {

    setDisableRegisterBtn(false);
    setLoginBtnShow(false);
    setQRtoggle(false);

    if (signUpQRData) {
      setSignUpQRData(null);
      setShowSignIn(true);
      return;
    }
    setShowSignIn(true);
  };



  const logInQR = async () => {

    setScale(true)

    setTimeout(() => {
      setScale(false)
    }, 3000);
    auth();
    const credential = localStorage.getItem("credentail");
    const authCredential = JSON.parse(credential);

    setTimeLeft(1000000)

    //log in fn for extention bethel
    if (authCredential) {
      const authRequest = await fetch(
        process.env.REACT_APP_BACKEND_URL + "sign-in"
      );
      const authData = await authRequest.json();

      const loginAuthHash =
        authData.body.scope[0].query.credentialSubject.auth.$eq;

      if (loginAuthHash === authCredential.auth) {
        Navigate("/dashboard");
      }
    }

    setRegisterBtnShow(false);
    setQRtoggle(true);
    setShowSignIn(false);
    setLoginBtnShow(false);
  };



  const handlePatse = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text");

    // Here we'll need to simulate a change event for the input manually
    const updatedValue = pastedText;
    setDid(updatedValue);

    if (updatedValue.length === 69) {
      setShowSubmitRegBtn(true);
    } else {
      setShowSubmitRegBtn(false);
    }
  };

  const handlePatse2 = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text");

    // Here we'll need to simulate a change event for the input manually
    const updatedValue = pastedText;
    setRegenDid(updatedValue);
  };

  // add login points into user
  const addLoginPoints = async (did) => {
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + "login-points", {
        method: "POST",
        body: JSON.stringify({
          "DID": did.id
        })
      })
      console.log(res)
    } catch (error) {
      console.log(error)
    }
  }

  // toggle values states
  const [toggleAndroid, setToggleAndroid] = useState(false);
  const [toggleIos, setToggleIos] = useState(false);

  // login and sign up button change
  const [loginBtnShow, setLoginBtnShow] = useState(true);
  const [registerBtnShow, setRegisterBtnShow] = useState(true);
  const [disableRegisterbtn, setDisableRegisterBtn] = useState(true);
  const [showSubmitRegBtn, setShowSubmitRegBtn] = useState(false);
  const [submitMsg, setSubmitMsg] = useState("Submit");

  // change register btn fn
  const changeRegisterBtnFn = () => {
    setDisableRegisterBtn(false);
  };

  function iOS() {
    return (
      ["iPad"].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  const [hideHelpSec, setHideHelpSec] = useState(false);


  const [regen, setRegen] = useState(false)
  const [regenDid, setRegenDid] = useState()
  const [showRegenDiv, setShowRegenDiv] = useState(false)
  const [regenMsg, setRegenMsg] = useState("Submit")

  // re genarate calim 
  const regenarate_claim_submit = async () => {
    console.log(regenDid)
    setRegenMsg("Loading")
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + `regenerate-reg-claim`, {
        method: "POST",
        body: JSON.stringify({
          "DID": regenDid
        })
      })

      console.log(res)
      if (res.status === 201) {
        const regenQR = await res.json()
        setSectionHide(false);
        const claim = await regenQR
        // show claim
        console.log(claim)
        setSignUpQRData(claim.claim);
        timeCountDown()

        setRegenMsg("Submit")

        setRegen(false)
        setShowRegenDiv(false)
      }
      else {
        setRegenMsg("Error")

      }
    } catch (error) {
      console.log(error)
      setRegenMsg("Error")

    }

  }

  //toggle regen did
  const regenareate_claim = () => {
    setShowRegenDiv(true)
    setRegen(false)
  }

  const [newDid, setNewDid] = useState()
  const [new_feature_claim, setNew_fetaure_calim] = useState(null)
  const [toggle_new_feature_qr, setToggle_new_feature_qr] = useState(false)
  const [toggle_main_div, setToggle_main_div] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)

  // set emergency qr data 
  const new_feature_qr = async () => {
    console.log(newDid)
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + `regenerate-reg-claim`, {
        method: "POST",
        body: JSON.stringify({
          "DID": newDid
        })
      })

      console.log(res)
      if (res.status === 201) {
        setToggle_new_feature_qr(true)
        const regenQR = await res.json()
        setSectionHide(false);
        const claim = await regenQR
        // show claim
        console.log(claim)
        setNew_fetaure_calim(claim.claim);

      }
      else {
        setErrorMsg(true)

        setTimeout(() => {
          setErrorMsg(false)
        }, 3000);

      }
    } catch (error) {
      console.log(error)
      setRegenMsg("Error")
      setErrorMsg(true)

      setTimeout(() => {
        setErrorMsg(false)
      }, 3000);

    }
  }

  // close qr 
  const close_new_feature_Qr = () => {
    setToggle_new_feature_qr(false)
    setToggle_main_div(false)
    logInQR()
  }

  const main_div_close = () => {
    setToggle_main_div(false)
    logInQR()
  }

  return (
    <section className="w-full flex h-screen min-[1024px]:bg-white min-[1100px]:bg-transparent md:bg-transparent sm:bg-black min-[320px]:bg-black">

      {/* maintaince annoucement */}
      <div className='w-full hidden'>

        <div className="fixed z-[99999] left-2 bottom-[100px] w-[500px] inline-block text-sm text-white border border-gray-200 rounded-lg shadow-sm bg-[#0F0F0F] animate__animated animate__fadeInLeft animate__fast">

          <div className="px-3 py-2 bg-bethel-green/60 border-b border-gray-200 rounded-t-lg flex gap-2 items-center">
            <div className="p-2 rounded-full bg-black">
              <img src={behtelIcon} className="w-[30px]" alt="" />
            </div>
            <h3 className="font-semibold text-[20px] text-white">Scheduled Maintenance</h3>
          </div>


          <div className="px-3 py-2 flex flex-col gap-3">
            <ul className="list-disc pl-5 text-[12px]">
              <li>We're gearing up for an upgrade to provide you for better experience.</li>
              <li className="">Some services will be unavailable on,</li>
              {/* <li>Some services will be unavailable on,<span className="text-bethel-newgreen font-bold"> 9th December at 07:30 EST / 22:30 AEST / 18:00 IST <span className="text-white">until</span>  09:30 EST / 00:30 AEST / 20:00 IST.</span></li> */}
            </ul>
          </div>


          <div className="absolute -bottom-1 left-20 transform translate-y-full w-6 h-6 border-t-[30px] border-t-[#0F0F0F] border-r-[30px] border-r-transparent"></div>
        </div>


        <div className='fixed -left-12 z-[999999] -bottom-[60px] -rotate-12 rounded-full p-5 animate__animated animate__fadeInLeft animate__fast'>
          <img src={robot} className='w-[100px] opacity-90 transform scale-x-[-1] animate-pulse' alt="" />
        </div>

      </div>
      {/* end of main tainre  */}

      {/* 3d object section */}
      <div className="absolute w-full h-screen bg-black z-[10000] hidden">
        <Canvas
          onCreated={({ camera, gl }) => {
            cameraRef.current = camera;
            gl.setClearColor('#000000'); // Set clear color to black for night sky
          }}
          camera={{ position: [0, 2, 9], fov: 55, near: 2 }}>
          {/* Ambient light for a subtle overall illumination */}
          <ambientLight intensity={0.1} />

          {/* Directional light to simulate moonlight */}
          <directionalLight
            position={[10, 10, 10]}
            intensity={0.5}
            color="#bbbbff" // Slightly blueish light to simulate moonlight
          />


          {pos && <OrbitControls autoRotate autoRotateSpeed={0.5} scale={2} />}
          {/* Stars for night sky */}
          <Stars
            radius={20}
            depth={10}
            count={5000}
            factor={4}
            saturation={10}
            fade
            brightness={10} // Increase brightness for stars
          />


          {/* 3D Model */}
          {/* <Scene />x */}

          {/* Camera Animation */}
          {!pos && <CameraAnimation camera={cameraRef.current} onAnimationComplete={handleAnimationComplete} />}


        </Canvas>
      </div>

      {/* login and register section */}
      <div className="w-full">
        <Version_login login_qr_data={qrCodeData} />
      </div>


      {/* version  */}
      <div className="absolute bottom-[10px] right-5 z-[999]">
        <h3 className="text-white text-[10px] mt-2">Version {version}</h3>
      </div>

    </section>
  );
}

export default Login;