import React, { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { useDispatch, useSelector } from 'react-redux'
import billingIcon from "../Images/icons/icons8-billing-64.png"
import PricePlanCard from './Cards/PricePlanCard'
import paperplanImage from "../Images/BG-Images/paper-rocket.png"
import planImage from "../Images/BG-Images/paper-plane.png"
import rocketImage from "../Images/BG-Images/rokcet1.png"
import iconClose from "../Images/icons/icons-close.png"
import $u from "../utils/$u.js";
import robot from "../Images/BG-Images/robot.png"
import verifyIcon from "../Images/icons/icon-verify.png"
import loadingGif from "../Images/Animation-gifs/Rolling-1s-200px(1).gif"
import iconDone from "../Images/icons/icon-newdone.png"
import requireAvalibilitySlice from '../reducers/RequireAvalibilySlice.js'
import planStatusSlice from '../reducers/PlanStatusSlice.js'
import iconExpired from "../Images/icons/icon-expired.png"
import CustomEnterprisePlan from './Cards/CustomEnterprisePlan.jsx'
import totalPointsSlice from '../reducers/totalPointsReducer.js'

const wc = require("../witness/wintess_calcutlator.js");
const snarkjs = require("snarkjs");

const fileStorageJSON = require("../json/FileStorage.json");
const fileStorageABI = fileStorageJSON.abi;
const fileStorageInterface = new ethers.utils.Interface(fileStorageABI);

const tokenABI = [
    'function name() view returns (string)',
    'function approve(address spender, uint256 amount) returns (bool)',
    'function transfer(address recipient, uint256 amount) returns (bool)',
];

const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS_FOR_TRANSFER
const mainWalletAddress = process.env.REACT_APP_RECIPIENT_ADDRESS

const fileStorageJSON2 = require("../json/FileStorage2.json");
const fileStorageAddress2 = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS_2
const fileStorageABI2 = fileStorageJSON2.abi

export default function DashboardBilling() {
    const [planStatus, setPlanStatus] = useState({ plan_name: "BASIC", is_adding: false, compelte: false, fail: false })
    const [contract, setContract] = useState()
    const [provider, setProvider] = useState()
    const [walletAddress, setWalletAddress] = useState(null)
    const [currentPackage, setCurrentPakcege] = useState(null)
    const [planActivateDuration, setPlanActivationDuration] = useState(null) //to get plan activate months
    const [basic_plan_status, setBasic_plan_status] = useState(false)
    const [extend_package_status, setExtend_pakage_status] = useState(false)

    const [activationError, setActivationError] = useState(false)

    const dispatch = useDispatch()

    const [addingUserStats, setAddingUserStats] = useState({
        s1: false, s2: false, s3: false, msg: '', addComplete: false, verifyComplete: false,
        planComplete: false, planFail: false, alldone: false
    })

    const [extend_pakage_status, setExtend_package_status] = useState({ is_loading: false, is_complete: false, err_msg: null })

    // plan informations 
    const [planInfo, setPlanInfo] = useState({ planSpace: null, planActivation: false, packageExpire: false })

    const [verifyEmailandMobile, setVerifyEmailandMobile] = useState({ mobile: null, email: null })

    const plan_status = useSelector((state) => state.requireAvalibilityReducer)



    useEffect(() => {
        if (!window.ethereum) {
            dispatch(requireAvalibilitySlice.actions.saveAvailibility({ metamask: true, useruserAdded: false, noUser: false, allreadyRegisteredWallet: false }))
            return
        }
        getInfo()
        current_package()
        checkPlanValidation()
        checkUserVerify()

    }, [])



    // get user did
    const did = useSelector((state) => state.DidReducer)

    // smart contract data
    const fileStorageAddress = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS;  // contract address
    const fileStorageJSON = require("../json/FileStorage.json");
    const fileStorageABI = fileStorageJSON.abi;

    // GET SMART CONTRACT INFO
    const getInfo = async () => {
        try {
            const providers = new ethers.providers.Web3Provider(window.ethereum)
            const signers = providers.getSigner()
            const contracts = new ethers.Contract(fileStorageAddress, fileStorageABI, signers)
            const walletAddress = await signers.getAddress()
            setWalletAddress(walletAddress)
            setProvider(providers)
            setContract(contracts)
        } catch (error) {
            console.log(error)
        }

    }

    // GET PACKAGE INFORMATION
    const current_package = async () => {
        const providers = new ethers.providers.Web3Provider(window.ethereum)
        const signers = providers.getSigner()
        const address = await signers.getAddress()
        const contracts = new ethers.Contract(fileStorageAddress2, fileStorageABI2, signers)

        try {
            const res = await contracts.getCurrentPackage(did, address)
            const basic_plan_status = await contracts.getActivatedFreePlanStatus(did)
            const deactivate_free_plan_status = await contracts.isActivatedFreePlan(did)
            const extend_package_status = await contracts.getExtendPeriodStatus(did)
            setCurrentPakcege(res)
            setExtend_pakage_status(extend_package_status)

            if (deactivate_free_plan_status || basic_plan_status) {
                setBasic_plan_status(true)
            }


            // set current package duration plan 
            const duration = parseInt(res._duration._hex)
            setPlanActivationDuration(duration)
        } catch (error) {
            console.log(error)
        }

    }

    const addBasicPlan = async () => {

        if (plan_status.noUser) {
            const result = await addUser()

            if (result === false) {
                return
            }
        }

        console.log(walletAddress)
        setPlanStatus((prev) => ({ ...prev, plan_name: "BASIC", is_adding: true, compelte: false }))
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + "free-space", {
                method: "POST",
                body: JSON.stringify({
                    "DID": did,
                    "Owner": walletAddress
                })
            })

            const hash = await res.json()
            if (res.status === 200) {
                provider
                    .waitForTransaction(hash.TXHash)
                    .then((_receipt) => {
                        if (_receipt.status === 0) {
                            console.log("Transaction failed.");

                            setPlanStatus((prev) => ({ ...prev, plan_name: "BASIC", is_adding: false, compelte: false }))

                            setPlanStatus((prev) => ({ ...prev, plan_name: "", is_adding: false, compelte: false, fail: true }))

                            setTimeout(() => {
                                setPlanStatus((prev) => ({ ...prev, plan_name: "", is_adding: false, compelte: false, fail: false }))
                            }, 3000);

                            setAddingUserStats((prev) => ({ ...prev, s1: false, msg: "", planComplete: false }))


                        } else {
                            console.log("Transaction successful.");

                            dispatch(requireAvalibilitySlice.actions.saveAvailibility({ metamask: false, userAdded: false, noUser: false, allreadyRegisteredWallet: false }))
                            dispatch(planStatusSlice.actions.saveStatus({ planActive: true, planExpire: false, planSize: false }))

                            setTimeout(() => {
                                current_package()
                            }, 2000);

                            setAddingUserStats((prev) => ({ ...prev, s1: true, msg: "", planComplete: true }))

                            setPlanStatus((prev) => ({ ...prev, plan_name: "BASIC", is_adding: false, compelte: true }))

                            setTimeout(() => {
                                setPlanStatus((prev) => ({ ...prev, plan_name: "BASIC", is_adding: false, compelte: false }))
                                setAddingUserStats((prev) => ({ ...prev, s1: false, msg: "", planComplete: false, verifyComplete: false }))

                            }, 3000);
                        }
                    })
                    .catch((error) => {
                        console.log("Errorss:", error);
                        setPlanStatus((prev) => ({ ...prev, plan_name: "", is_adding: false, compelte: false, fail: true }))

                        setTimeout(() => {
                            setPlanStatus((prev) => ({ ...prev, plan_name: "", is_adding: false, compelte: false, fail: false }))
                        }, 3000);

                        setAddingUserStats((prev) => ({ ...prev, s1: false, msg: "", verifyComplete: false }))

                    })
            }

        } catch (error) {
            setPlanStatus((prev) => ({ ...prev, plan_name: "BASIC", is_adding: false, compelte: false }))

            setPlanStatus((prev) => ({ ...prev, plan_name: "", is_adding: false, compelte: false, fail: true }))

            setTimeout(() => {
                setPlanStatus((prev) => ({ ...prev, plan_name: "", is_adding: false, compelte: false, fail: false }))
            }, 3000);

            setAddingUserStats((prev) => ({ ...prev, s1: false, msg: "", planComplete: false }))
        }
    }

    const addStaterPlan = async () => {

        if (!verifyEmailandMobile.email.is_verified) {
            setActivationError(true)
            setPlanStatus((prev) => ({ ...prev, plan_name: "STARTER", is_adding: false, compelte: false }))

            setTimeout(() => {
                setActivationError(false)
            }, 3000);
            return
        }


        setPlanStatus((prev) => ({ ...prev, plan_name: "STARTER", is_adding: true, compelte: false }))

        if (plan_status.noUser) {
            const result = await addUser()

            if (result === false) {
                return
            }

        }

        // check if the plan duration month
        let duration = 0
        let monthDur = null

        // check if the plan duration month
        switch (true) {
            case planPeriod.month:
                duration = 1
                monthDur = "MONTH_1_STARTER"
                break;

            case planPeriod.month3:
                duration = 3
                monthDur = "MONTH_3_STARTER"
                break;

            case planPeriod.month6:
                duration = 6
                monthDur = "MONTH_6_STARTER"
                break;

            case planPeriod.year:
                duration = 12
                monthDur = "MONTH_12_STARTER"
                break;

            default:
                break;
        }

        //becx transfer ammount 
        const becx = await priceCalc(duration, monthDur)

        // becx calculate
        try {
            // send token for becx
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(tokenAddress, tokenABI, signer)

            const owner = await signer.getAddress()

            // transfer into a wei value 
            const amountInWei = ethers.utils.parseUnits(becx, 18); //calcBECX.toString()

            // Approve the recipient to spend the tokens
            const approveTx = await contract.approve(owner, amountInWei);
            const approvetx = await approveTx.wait();

            // Transfer the tokens from the owner (signer) to the recipient
            const transferTx = await contract.transfer(mainWalletAddress, amountInWei);
            const trasnfertx = await transferTx.wait();

            console.log("trasaction Complete")
        } catch (error) {

            console.log("error:-", error.message)
            setPlanStatus((prev) => ({ ...prev, plan_name: "STARTER", is_adding: false, compelte: false, fail: true }))

            setTimeout(() => {
                setPlanStatus((prev) => ({ ...prev, plan_name: "STARTER", is_adding: false, compelte: false, fail: false }))

            }, 3000);

            setAddingUserStats((prev) => ({ ...prev, s1: false, s2: false, msg: "", addComplete: false }))

            return
        }

        try {
            // const providers = new ethers.providers.Web3Provider(window.ethereum)
            // const signers = providers.getSigner()
            // const contracts = new ethers.Contract(fileStorageAddress2, fileStorageABI2, signers)
            // const res = await contracts.getPaidSpace(1, did)


            const res = await fetch(process.env.REACT_APP_BACKEND_URL + `active-package`, {
                method: "POST",
                body: JSON.stringify({
                    "DID": did,
                    "PackageType": 1,
                    "Duration": duration,
                    "Owner": walletAddress
                })
            })
            console.log(res)

            const hash = await res.json()

            if (res.status === 200) {
                provider
                    .waitForTransaction(hash.TXHash)
                    .then((_receipt) => {
                        if (_receipt.status === 0) {

                            setPlanStatus((prev) => ({ ...prev, plan_name: "STARTER", is_adding: false, compelte: false, fail: true }))

                            setTimeout(() => {
                                setPlanStatus((prev) => ({ ...prev, plan_name: "STARTER", is_adding: false, compelte: false, fail: false }))

                            }, 3000);
                        } else {
                            addPointsPlans(monthDur, duration) //add plan points 
                            setAddingUserStats((prev) => ({ ...prev, s1: false, msg: "", verifyComplete: false }))
                            setPlanStatus((prev) => ({ ...prev, plan_name: "STARTER", is_adding: false, compelte: true }))
                            current_package()
                            setTimeout(() => {
                                setPlanStatus((prev) => ({ ...prev, plan_name: "STARTER", is_adding: false, compelte: false }))
                            }, 3000);

                            dispatch(planStatusSlice.actions.saveStatus({ planActive: true, planExpire: false, planSpace: null })) // close plan expire 
                        }
                    })
                    .catch((error) => {
                        console.log("Errorss:", error);
                        setPlanStatus((prev) => ({ ...prev, plan_name: "STARTER", is_adding: false, compelte: false, fail: true }))

                        setTimeout(() => {
                            setPlanStatus((prev) => ({ ...prev, plan_name: "STARTER", is_adding: false, compelte: false, fail: false }))

                        }, 3000);
                    })
            }
            else {
                setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: false, compelte: false, fail: true }))

                setTimeout(() => {
                    setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: false, compelte: false, fail: false }))
                }, 3000);
            }


        } catch (error) {
            setPlanStatus((prev) => ({ ...prev, plan_name: "STARTER", is_adding: false, compelte: false, fail: true }))

            setTimeout(() => {
                setPlanStatus((prev) => ({ ...prev, plan_name: "STARTER", is_adding: false, compelte: false, fail: false }))
            }, 3000);
        }
    }

    // calculate becx price 
    const priceCalc = async (duration, monthDur) => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + `get-plan-price`, {
                method: "POST",
                body: JSON.stringify({
                    "Plan": monthDur,
                    "Month": duration
                })
            })

            if (res.status === 200) {
                const becx = await res.json()
                const calcBecx = (becx.Price).toString()
                return calcBecx
            }
            else {
                setPlanStatus((prev) => ({ ...prev, plan_name: "STARTER", is_adding: false, compelte: false, fail: true }))

                setTimeout(() => {
                    setPlanStatus((prev) => ({ ...prev, plan_name: "STARTER", is_adding: false, compelte: false, fail: false }))

                }, 3000);
            }


        } catch (error) {
            setPlanStatus((prev) => ({ ...prev, plan_name: "STARTER", is_adding: false, compelte: false, fail: true }))

            setTimeout(() => {
                setPlanStatus((prev) => ({ ...prev, plan_name: "STARTER", is_adding: false, compelte: false, fail: false }))

            }, 3000);
        }
    }

    const addAdvancePlan = async () => {

        setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: true, compelte: false }))

        if (!verifyEmailandMobile.email.is_verified) {
            setActivationError(true)
            setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: false, compelte: false }))

            setTimeout(() => {
                setActivationError(false)
            }, 3000);
            return
        }


        setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: true, compelte: false }))

        if (plan_status.noUser) {
            const result = await addUser()

            if (result === false) {
                return
            }

        }

        //calculate becx for the plan 
        // check if the plan duration month
        // check if the plan duration month
        let duration = 0
        let monthDur = null

        // check if the plan duration month
        switch (true) {
            case planPeriod.month:
                duration = 1
                monthDur = "MONTH_1_ADVANCE"
                break;

            case planPeriod.month3:
                duration = 3
                monthDur = "MONTH_3_ADVANCE"
                break;

            case planPeriod.month6:
                duration = 6
                monthDur = "MONTH_6_ADVANCE"
                break;

            case planPeriod.year:
                duration = 12
                monthDur = "MONTH_12_ADVANCE"
                break;

            default:
                break;
        }

        //becx transfer ammount 
        const becx = await priceCalc(duration, monthDur)

        // transfer ammount and approve contract
        try {
            // send token for becx
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(tokenAddress, tokenABI, signer)

            const owner = await signer.getAddress()

            // transfer into a wei value 
            const amountInWei = ethers.utils.parseUnits(becx, 18); // calcBECX.toString()

            // Approve the recipient to spend the tokens
            const approveTx = await contract.approve(owner, amountInWei);
            const approvetx = await approveTx.wait();


            // Transfer the tokens from the owner (signer) to the recipient
            const transferTx = await contract.transfer(mainWalletAddress, amountInWei);
            const trasnfertx = await transferTx.wait();

        } catch (error) {

            console.log("error:-", error.message)
            setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: false, compelte: false, fail: true }))

            setTimeout(() => {
                setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: false, compelte: false, fail: false }))

            }, 3000);
            return
        }

        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + `active-package`, {
                method: "POST",
                body: JSON.stringify({
                    "DID": did,
                    "PackageType": 2,
                    "Duration": duration
                })
            })
            console.log(res)
            const hash = await res.json()

            if (res.status === 200) {
                provider
                    .waitForTransaction(hash.TXHash)
                    .then((_receipt) => {
                        console.log("Receipt:", _receipt);
                        if (_receipt.status === 0) {

                            setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: false, compelte: false, fail: true }))

                            setTimeout(() => {
                                setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: false, compelte: false, fail: false }))
                            }, 3000);
                        } else {
                            console.log("Transaction successful.");

                            addPointsPlans(monthDur, duration) //add plan points 
                            setAddingUserStats((prev) => ({ ...prev, s1: false, msg: "", verifyComplete: false }))
                            setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: false, compelte: true }))
                            current_package()
                            setTimeout(() => {
                                setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: false, compelte: false }))
                            }, 3000);

                            dispatch(planStatusSlice.actions.saveStatus({ planActive: true, planExpire: false, planSpace: null })) // close plan expire 

                        }
                    })
                    .catch((error) => {
                        console.log("Errorss:", error);
                        setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: false, compelte: false, fail: true }))

                        setTimeout(() => {
                            setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: false, compelte: false, fail: false }))
                        }, 3000);
                    })
            }
            else {
                setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: false, compelte: false, fail: true }))

                setTimeout(() => {
                    setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: false, compelte: false, fail: false }))
                }, 3000);
            }

        } catch (error) {
            setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: false, compelte: false, fail: true }))

            setTimeout(() => {
                setPlanStatus((prev) => ({ ...prev, plan_name: "ADVANCE", is_adding: false, compelte: false, fail: false }))
            }, 3000);
        }
    }

    // add points for the plans adding
    const addPointsPlans = async (Package, Month) => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + "package-points", {
                method: "POST",
                body: JSON.stringify({
                    "DID": did,
                    "Package": Package,
                    "Month": Month
                })
            })

            if (res.status === 200) {
                getTotalPoints()
            }
        } catch (error) {

        }
    }

    // get total points 
    const getTotalPoints = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + "get-counts", {
                method: "POST",
                body: JSON.stringify({
                    "DID": did
                })
            })

            const totalPoints = await res.json()
            console.log(totalPoints)
            dispatch(totalPointsSlice.actions.saveTotalPoints({ totalPoints: totalPoints.Points }))

        } catch (error) {
            console.log(error)
        }
    }

    // renew plan 
    const renewPlan = async () => {

        // eslint-disable-next-line default-case
        switch (currentPackage && currentPackage._packageName) {
            case "Starter":
                addStaterPlan()
                break;
            case "Advance":
                addAdvancePlan()
                break;
        }
    }

    // user adding plan funtion
    const addUser = async () => {

        // check if user added into this wallet addres
        const UserAddedRes = await contract.getUserDid()
        console.log(UserAddedRes)

        if (UserAddedRes === true) {
            dispatch(requireAvalibilitySlice.actions.saveAvailibility({ metamask: false, userAdded: false, noUser: false, allreadyRegisteredWallet: true }))
            return false
        }
        // setPlanStatus((prev) => ({ ...prev, plan_name: "BASIC", is_adding: true, compelte: false }))

        setAddingUserStats((prev) => ({ ...prev, s1: true, s2: true, msg: "", addComplete: false }))
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner()
            const address = signer.getAddress()

            const acc = await address


            const secret = ethers.BigNumber.from(
                ethers.utils.randomBytes(32)
            ).toString();
            const nullifier = ethers.BigNumber.from(
                ethers.utils.randomBytes(32)
            ).toString();

            const input = {
                secret: $u.BN256ToBin(secret).split(""),
                nullifier: $u.BN256ToBin(nullifier).split(""),
            };
            const jsonContent = JSON.stringify(input, null, 4);
            var res = await fetch("/addUser.wasm");
            var buffer = await res.arrayBuffer();
            var adduserWC = await wc(buffer);

            const r = await adduserWC.calculateWitness(input, 0);

            const commitment = r[1];
            console.log("comitnemnt :", commitment)
            const nullifierHash = r[2];
            const value = ethers.BigNumber.from("100000000000000000").toHexString();

            const resAddUser = await fetch(process.env.REACT_APP_BACKEND_URL + "add-user", {
                method: "POST",
                body: JSON.stringify({
                    "Commitment": commitment.toString(),
                    "Did": did,
                    "NullifierHash": nullifierHash.toString(),
                    "Owner": acc
                })
            })

            if (resAddUser.status === 200) {
                const add_user_data = await resAddUser.json()

                try {
                    const proofElements = {
                        nullifierHash: `${nullifierHash}`,
                        secret: secret,
                        nullifier: nullifier,
                        commitment: `${commitment}`,
                        txHash: add_user_data.TXHash,
                    };

                    console.log("proof elemenmt", proofElements)

                    const proof_element = btoa(JSON.stringify(proofElements));

                    provider
                        .waitForTransaction(add_user_data.TXHash)
                        .then((_receipt) => {
                            if (_receipt.status === 0) {
                                console.log("Transaction failed.");

                                setAddingUserStats((prev) => ({ ...prev, s1: false, s2: false, msg: "", addComplete: false }))

                            } else {
                                console.log("Transaction successful.");
                                setAddingUserStats((prev) => ({ ...prev, s1: true, s2: true, msg: "", addComplete: true }))

                                setAddingUserStats((prev) => ({ ...prev, s1: true, s2: false, s3: true, msg: "", addComplete: true, verifyComplete: true }))

                                // addBasicPlan()


                                setTimeout(() => {
                                    setAddingUserStats((prev) => ({ ...prev, s1: true, s2: false, s3: false, msg: "", addComplete: true, verifyComplete: true }))
                                }, 3000);
                            }
                        })
                        .catch((error) => {
                            console.log("Errorss:", error);
                        })

                    setAddingUserStats((prev) => ({ ...prev, s1: true, s2: false, msg: "", addComplete: true }))

                    // run verification process function
                    // await verifyUser(proof_element, acc, provider)



                } catch (e) {
                    console.log(e);
                    setAddingUserStats((prev) => ({ ...prev, s1: false, s2: false, msg: "", addComplete: false }))

                }
            } else {

            }
        } catch (error) {
            console.log(error)
        }


    };

    //verify user 
    const verifyUser = async (proof_element, acc, provider, claim) => {

        setAddingUserStats((prev) => ({ ...prev, s1: true, s2: true, s3: true, msg: "", addComplete: true, verifyComplete: false }))
        try {
            const proofElements = JSON.parse(atob(proof_element));

            const receipt = await window.ethereum.request({
                method: "eth_getTransactionReceipt",
                params: [proofElements.txHash],
            });
            if (!receipt) {
                throw "empty-receipt";
            }


            const log = receipt.logs[1];


            const decodedData = await fileStorageInterface.decodeEventLog(
                "UserCollected",
                log.data,
                log.topics
            );
            console.log("Decoded Data:", decodedData);

            const SnarkJS = window["snarkjs"];

            const proofInput = {
                root: $u.BNToDecimal(decodedData.useroot),
                nullifierHash: proofElements.nullifierHash,
                recipient: $u.BNToDecimal(acc),
                secret: $u.BN256ToBin(proofElements.secret).split(""),
                nullifier: $u.BN256ToBin(proofElements.nullifier).split(""),
                hashPairings: decodedData.userHashPairings.map((n) =>
                    $u.BNToDecimal(n)
                ),
                hashDirections: decodedData.userPairDirection,
            };

            const { proof, publicSignals } = await snarkjs.groth16.fullProve(
                proofInput,
                "/verifyUser.wasm",
                "/setup_final.zkey"
            );

            const providers = new ethers.providers.Web3Provider(window.ethereum)
            const accounts = await providers.send('eth_requestAccounts', []);

            const callInputs = [
                proof.pi_a.slice(0, 2).map($u.BN256ToHex),
                proof.pi_b
                    .slice(0, 2)
                    .map((row) => $u.reverseCoordinate(row.map($u.BN256ToHex))),
                proof.pi_c.slice(0, 2).map($u.BN256ToHex),
                publicSignals.slice(0, 2).map($u.BN256ToHex),
                did
            ];

            console.log("proof elemenmt", proofElements)
            console.log("proof inpout", proofInput)

            const resUserVerify = await fetch(process.env.REACT_APP_BACKEND_URL + "verify-user", {
                method: "POST",
                body: JSON.stringify(
                    {
                        "A": JSON.stringify(proof.pi_a.slice(0, 2).map($u.BN256ToHex)),
                        "B": JSON.stringify(proof.pi_b
                            .slice(0, 2)
                            .map((row) => $u.reverseCoordinate(row.map($u.BN256ToHex)))),
                        "C": JSON.stringify(proof.pi_c.slice(0, 2).map($u.BN256ToHex)),
                        "Inputs": JSON.stringify(publicSignals.slice(0, 2).map($u.BN256ToHex)),
                        "Did": did,
                        "Owner": accounts[0]
                    }
                )
            })

            if (resUserVerify.status === 200) {
                const tx_hash = await resUserVerify.json()

                provider
                    .waitForTransaction(tx_hash.TXHash)
                    .then((_receipt) => {
                        if (_receipt.status === 0) {
                            console.log("Transaction failed.");
                            setAddingUserStats((prev) => ({ ...prev, s1: false, s2: false, s3: false, msg: "", addComplete: false, verifyComplete: false }))

                        } else {
                            console.log("Transaction successful");
                            setAddingUserStats((prev) => ({ ...prev, s1: true, s2: false, s3: true, msg: "", addComplete: true, verifyComplete: true }))

                            addBasicPlan()


                            setTimeout(() => {
                                setAddingUserStats((prev) => ({ ...prev, s1: true, s2: false, s3: false, msg: "", addComplete: true, verifyComplete: true }))
                            }, 3000);

                        }
                    })
            }
            else {
                setAddingUserStats((prev) => ({ ...prev, s1: false, s2: false, s3: false, msg: "", addComplete: false }))

            }
        } catch (error) {
            console.log(error) //if fails the actions
            setAddingUserStats((prev) => ({ ...prev, s1: false, s2: false, s3: false, msg: "", addComplete: false }))

        }
    };

    // check all plan validation fn
    const checkPlanValidation = async () => {
        const provieder = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provieder.getSigner()
        const contract = new ethers.Contract(fileStorageAddress2, fileStorageABI2, signer)

        try {
            const packageSpace = await contract.checkPackageSpace(did)
            const planActivation = await contract.checkPackageActivate(did)
            const planExpire = await contract.checkPackageExpire(did)

            setPlanInfo((prev) => ({ ...prev, planSpace: parseInt(packageSpace), planActivation: planActivation, packageExpire: planExpire }))
        } catch (error) {
            console.log(error)
            setPlanInfo((prev) => ({ ...prev, packageExpire: true }))

        }

    }

    const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)

    //chck plan expire or not 
    const planExpired = useSelector((state) => state.planStatusReducer)

    // month selection
    const [planPeriod, setPlanPeriod] = useState({ month: true, month3: false, month6: false, year: false })

    // select plan fn 
    const selectPeriod = (duration) => {
        if (duration === "1") {
            setPlanPeriod((prev) => ({ ...prev, month: true, month3: false, month6: false, year: false }))
        }
        if (duration === "3") {
            setPlanPeriod((prev) => ({ ...prev, month: false, month3: true, month6: false, year: false }))
        }
        if (duration === "6") {
            setPlanPeriod((prev) => ({ ...prev, month: false, month3: false, month6: true, year: false }))
        }
        if (duration === "12") {
            setPlanPeriod((prev) => ({ ...prev, month: false, month3: false, month6: false, year: true }))
        }
    }

    // set plan duration in plans menu
    const setCurrentPlan = () => {
        switch (planActivateDuration) {
            case 1:
                setPlanPeriod((prev) => ({ ...prev, month: true }))
                break;
            case 3:
                setPlanPeriod((prev) => ({ ...prev, month: false, month3: true }))
                break;
            case 6:
                setPlanPeriod((prev) => ({ ...prev, month: false, month6: true }))
                break;
            case 12:
                setPlanPeriod((prev) => ({ ...prev, month: false, year: true }))
                break;
            default:
                setPlanPeriod((prev) => ({ ...prev, month: true }))
                break;
        }
    }

    // check if user is mobile verify or email verified 
    const checkUserVerify = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + `get-email-verify?OwnerDid=${did}`)
            const emailData = await res.json()

            const res2 = await fetch(process.env.REACT_APP_BACKEND_URL + `get-phone-number-verify?OwnerDid=${did}`)
            const mobileData = await res2.json()

            setVerifyEmailandMobile((prev) => ({ ...prev, mobile: mobileData, email: emailData }))

        } catch (error) {
            console.log(error)
        }
    }

    // extend the packege for two weeks
    const extend_package = async () => {
        setExtend_package_status((prev) => ({ ...prev, is_loading: true }))
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + "extend-package", {
                method: "POST",
                body: JSON.stringify({
                    "DID": did
                })
            })

            if (res.ok) {
                const tx_hash = await res.json()

                provider
                    .waitForTransaction(tx_hash.TXHash)
                    .then((_receipt) => {
                        if (_receipt.status === 0) {
                            setExtend_package_status((prev) => ({ ...prev, is_loading: false, err_msg: "Package Extend fail" }))

                            setTimeout(() => {
                                setExtend_package_status((prev) => ({ ...prev, err_msg: null }))
                            }, 3000);

                        } else {
                            console.log("Transaction successful.");
                            setExtend_package_status((prev) => ({ ...prev, is_loading: true, is_complete: true }))
                            setTimeout(() => {
                                setExtend_package_status((prev) => ({ ...prev, is_loading: false, is_complete: false }))
                            }, 3000);
                        }
                    })
                    .catch((error) => {
                        setExtend_package_status((prev) => ({ ...prev, is_loading: false, err_msg: "Package Extend fail" }))

                        setTimeout(() => {
                            setExtend_package_status((prev) => ({ ...prev, err_msg: null }))
                        }, 3000);
                    })
            } else {
                setExtend_package_status((prev) => ({ ...prev, is_loading: false, err_msg: "Package Extend fail" }))

                setTimeout(() => {
                    setExtend_package_status((prev) => ({ ...prev, err_msg: null }))
                }, 3000);
            }
        } catch (error) {
            setExtend_package_status((prev) => ({ ...prev, is_loading: false, err_msg: "Package Extend fail" }))

            setTimeout(() => {
                setExtend_package_status((prev) => ({ ...prev, err_msg: null }))
            }, 3000);

        }

    }

    useEffect(() => {
        setCurrentPlan()
    }, [planActivateDuration])

    return (
        <div className={`w-full flex flex-col px-4  ${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded ? "" : "pointer-events-none grayscale blur-sm"}`}>

            {activationError &&
                <div className='fixed bottom-2 right-2 w-auto rounded-lg  border px-4 py-2 border-red-500 text-white bg-red-600 z-[999] animate__animated animate__fadeIn'>
                    <h3 className='font-semibold'>Please Verify Email to Activate Plans</h3>
                </div>
            }

            {/* user adding funcntion robot status */}
            {addingUserStats.s1 && <div>
                <div className="fixed z-[99999] right-8 bottom-[210px] w-[400px] inline-block text-sm text-white border border-gray-200 rounded-lg shadow-sm bg-green-400 animate__animated animate__fadeInRight animate__fast">
                    {/* Header with MetaMask Logo */}
                    <div className="px-3 py-2 bg-green-600 border-b border-gray-200 rounded-t-lg flex gap-2 items-center">
                        <div className="p-2 rounded-full bg-white">
                            <img src={verifyIcon} className="w-[30px]" alt="" />
                        </div>
                        <h3 className="font-semibold text-white text-[16px]">User Verification in progress !</h3>
                    </div>

                    {/* Content */}
                    <div className="px-3 py-4 flex flex-col">
                        <h4 className='font-bold text-black mb-3'>User adding and verifying in progress. Please do not close this window nor refresh page ! </h4>
                        <ul className="list-disc pl-5 text-black/70 font-bold text-[14px] flex flex-col gap-1">
                            <div className='flex items-center gap-2'>
                                <li>User adding ...</li>
                                {!addingUserStats.addComplete && <img src={loadingGif} className='w-[25px]' alt="" />}
                                {addingUserStats.addComplete && <img src={iconDone} className='w-[25px]' alt="" />}
                            </div>
                            <div className='flex items-center gap-2'>
                                <li>User Verifying...</li>
                                {!addingUserStats.verifyComplete ? <img src={loadingGif} className='w-[25px]' alt="" /> :
                                    <img src={iconDone} className='w-[25px]' alt="" />}
                            </div>
                            <div className='flex items-center gap-2'>
                                <li>Plan is adding ...</li>
                                {!addingUserStats.planComplete && <img src={loadingGif} className='w-[25px]' alt="" />}
                                {addingUserStats.planComplete && <img src={iconDone} className='w-[25px]' alt="" />}
                            </div>

                        </ul>
                    </div>

                    {/* Triangle Pointer */}
                    <div className="absolute bottom-0 right-20 transform translate-y-full w-6 h-6 border-t-[30px] border-t-green-400 border-l-[30px] border-l-transparent"></div>
                </div>

                {/* div ai robot div */}
                <div className='z-[1000] fixed -bottom-10 -right-12 -rotate-6 rounded-full p-5 animate__animated animate__fadeInRight animate__fast'>
                    <img src={robot} className='w-[150px] opacity-100 ' alt="" />
                </div>
            </div>}


            {/* re new plan btn */}
            {planExpired.planExpire && ((currentPackage && currentPackage._packageName) === "Starter" || (currentPackage && currentPackage._packageName) === "Advance") &&
                <div>
                    <div className="fixed z-[99999] right-8 bottom-[210px] w-[500px] inline-block text-sm text-white border border-gray-200 rounded-lg shadow-sm bg-red-400 animate__animated animate__fadeInRight animate__fast">
                        {/* Header with MetaMask Logo */}
                        <div className="px-3 py-2 bg-red-600 border-b border-gray-200 rounded-t-lg flex gap-2 items-center justify-between">
                            <div className="p-2 rounded-full bg-white">
                                <img src={iconExpired} className="w-[30px]" alt="" />
                            </div>
                            <h3 className="font-semibold text-white text-[16px]">Oops! Plan was Expired</h3>

                            <button disabled={planStatus.is_adding} onClick={renewPlan} className='flex px-4 py-2 border-white border-[1px] rounded-lg hover:bg-bethel-green hover:scale-105'>
                                Re-New
                            </button>

                            {extend_package_status && <button disabled={extend_pakage_status.is_loading} onClick={extend_package} className='flex px-4 py-2 border-white border-[1px] rounded-lg hover:bg-bethel-green hover:scale-105'>
                                Extend
                            </button>}
                        </div>

                        {/* Content */}
                        <div className="px-3 py-4 flex flex-col">
                            <h4 className='font-bold text-black mb-3'>Click 'Re-New' to Re-Activate your plan again! </h4>
                            {extend_package_status && <h4 className='font-bold text-black mb-3'>Click 'Extend plan' to extend your plan for two weeks</h4>}
                            <div className='px-2 flex items-center gap-2 animate-pulse'>
                                {planStatus.is_adding && <div className='font-bold text-white text-[16px]'>Updating Plan ... </div>}
                                {planStatus.is_adding && <div>
                                    {planStatus.is_adding && <img src={loadingGif} className='w-[25px]' alt="" />}
                                    {planStatus.compelte && <img src={iconDone} className='w-[25px]' alt="" />}
                                </div>}

                                {extend_pakage_status.is_loading && <div className='font-bold text-white text-[16px]'>Extending Plan ... </div>}
                                {extend_pakage_status.is_loading && <div>
                                    {!extend_pakage_status.is_complete && <img src={loadingGif} className='w-[25px]' alt="" />}
                                    {extend_pakage_status.is_complete && <img src={iconDone} className='w-[25px]' alt="" />}
                                </div>}


                                {extend_pakage_status.err_msg &&
                                    <div className='flex w-auto rounded-lg  border px-4 py-2 border-red-500 text-white bg-red-600 z-[999] animate__animated animate__fadeIn'>
                                        <h3 className='font-semibold'>{extend_pakage_status.err_msg}</h3>
                                    </div>
                                }


                            </div>
                        </div>

                        {/* Triangle Pointer */}
                        <div className="absolute bottom-0 right-20 transform translate-y-full w-6 h-6 border-t-[30px] border-t-red-400 border-l-[30px] border-l-transparent"></div>
                    </div>

                    {/* div ai robot div */}
                    <div className='z-[1000] fixed -bottom-10 -right-12 -rotate-6 rounded-full p-5 animate__animated animate__fadeInRight animate__fast'>
                        <img src={robot} className='w-[150px] opacity-100 ' alt="" />
                    </div>
                </div>}


            {/* title section */}
            <div onClick={setCurrentPlan} className='flex items-center w-full gap-2 px-2 py-2 relative'>
                <img src={billingIcon} className='w-[20px]' alt="" />
                <h3 className='text-white text-[1rem]'>BILLING</h3>
            </div>


            {/* select plan buttons */}
            <div className='w-full  rounded-lg py-3 flex flex-col gap-4 '>
                {/* plan choose container */}
                <div className='flex w-full gap-4'>
                    {/* default plans */}
                    <button disabled={planStatus.is_adding === true} onClick={() => selectPeriod("1")} className={`flex p-4 text-center font-bold justify-center w-full  text-white border-[1px] border-white/40 rounded-lg 
                        ${planPeriod.month ? "bg-white/10" : ""}`}>
                        <h3 className=''>1 Month Plan</h3>
                    </button>

                    {/* default plans */}
                    <button disabled={planStatus.is_adding === true} onClick={() => selectPeriod("3")} className={`flex p-4 text-center font-bold justify-center w-full  text-white border-[1px] border-white/40 rounded-lg 
                        ${planPeriod.month3 ? "bg-white/10" : ""}`}>
                        <h3 className=''>3 Months Plan</h3>
                    </button>

                    {/* default plans */}
                    <button disabled={planStatus.is_adding === true} onClick={() => selectPeriod("6")} className={`flex p-4 text-center font-bold justify-center w-full  text-white border-[1px] border-white/40 rounded-lg 
                        ${planPeriod.month6 ? "bg-white/10" : ""}`}>
                        <h3 className=''>6 Months Plan</h3>
                    </button>

                    {/* default plans */}
                    <button disabled={planStatus.is_adding === true} onClick={() => selectPeriod("12")} className={`flex p-4 text-center font-bold justify-center w-full  text-white border-[1px] border-white/40 rounded-lg 
                        ${planPeriod.year ? "bg-white/10" : ""}`}>
                        <h3 className=''>1 Year Plan</h3>
                    </button>
                </div>



            </div>

            {/* content section */}

            {/* plans conteainer  for 1 month*/}
            {planPeriod.month && <div className='w-full flex bg-green-900/10 rounded-lg px-2'>
                <div className='flex w-full  px-2 py-5 gap-5 flex-col'>
                    <div className='w-full flex lg:flex-row md:flex-col sm:flex-col min-[320px]:flex-col gap-5'>
                        <PricePlanCard plan_image={paperplanImage} price={0} plan_name={"FREE PLAN"} plan_space={"1GB"} onBtnClick={addBasicPlan} currentPlan={currentPackage && currentPackage._packageName === "Basic" ? true : false}
                            plan_status={(planStatus.plan_name === "BASIC" && planStatus.is_adding === true) ? true : false}
                            plan_active={(basic_plan_status || currentPackage === "Basic") ? true : false}
                            plan_info={planInfo}
                            duration={planActivateDuration && planActivateDuration === 1 ? true : false}
                            plan_perioad={planPeriod}
                            plan_status2={(planStatus.is_adding === true) ? true : false}
                            plan_expire={planExpired && planExpired.planExpire}
                            plan_description={"Ideal for individual users looking to safely store and manage their data"}
                        />

                        <PricePlanCard plan_image={planImage} price={10} becx={12} plan_name={"STARTER PLAN"} plan_space={"1TB"} onBtnClick={addStaterPlan} currentPlan={currentPackage && currentPackage._packageName === "Starter" ? true : false}
                            plan_status={(planStatus.plan_name === "STARTER" && planStatus.is_adding === true) ? true : false}
                            plan_info={planInfo}
                            plan_perioad={planPeriod}
                            plan_status2={(planStatus.is_adding === true) ? true : false}
                            duration={planActivateDuration && planActivateDuration === 1 ? true : false}
                            plan_expire={planExpired && planExpired.planExpire}
                            plan_description={"Perfect for those managing large files or extensive data, ensuring your information is stored safely."}
                        />

                        <PricePlanCard nCard plan_image={rocketImage} price={30} plan_name={"ADVANCE PLAN"} plan_space={"5TB"} onBtnClick={addAdvancePlan} currentPlan={currentPackage && currentPackage._packageName === "Advance" ? true : false}
                            plan_status={(planStatus.plan_name === "ADVANCE" && planStatus.is_adding === true) ? true : false}
                            plan_perioad={planPeriod}
                            plan_status2={(planStatus.is_adding === true) ? true : false}
                            plan_info={planInfo}
                            duration={planActivateDuration && planActivateDuration === 1 ? true : false}
                            plan_expire={planExpired && planExpired.planExpire}
                            plan_description={"This plan is perfect for those needing large-scale, high-security storage solutions, ensuring that all your data is protected"}
                        />
                    </div>

                    {/* custom user selection plan */}
                    <CustomEnterprisePlan user_verify={verifyEmailandMobile && verifyEmailandMobile} did={did} wallet_address={walletAddress} />


                </div>
            </div>}

            {/* plans conteainer  for 3 month*/}
            {planPeriod.month3 &&
                <div className='w-full flex bg-green-900/10 rounded-lg px-2'>
                    <div className='flex w-full  px-2 py-5 gap-5 flex-col'>
                        <div className='w-full flex lg:flex-row md:flex-col sm:flex-col min-[320px]:flex-col gap-5'>

                            <PricePlanCard plan_image={planImage} price={planPeriod.month3 ? 30 : ""} plan_name={"STARTER PLAN"} plan_space={1000} onBtnClick={addStaterPlan} currentPlan={currentPackage && currentPackage._packageName === "Starter" ? true : false}
                                plan_status={(planStatus.plan_name === "STARTER" && planStatus.is_adding === true) ? true : false}
                                plan_perioad={planPeriod}
                                plan_info={planInfo}
                                plan_status2={(planStatus.is_adding === true) ? true : false}
                                duration={planActivateDuration && planActivateDuration === 3 ? true : false}
                                plan_expire={planExpired && planExpired.planExpire}
                                plan_description={"Perfect for those managing large files or extensive data, ensuring your information is stored safely."}
                            />

                            <PricePlanCard plan_image={rocketImage} price={planPeriod.month3 ? 90 : ""} plan_name={"ADVANCE PLAN"} plan_space={5000} onBtnClick={addAdvancePlan} currentPlan={currentPackage && currentPackage._packageName === "Advance" ? true : false}
                                plan_status={(planStatus.plan_name === "ADVANCE" && planStatus.is_adding === true) ? true : false}
                                plan_perioad={planPeriod}
                                plan_info={planInfo}
                                plan_status2={(planStatus.is_adding === true) ? true : false}
                                duration={planActivateDuration && planActivateDuration === 3 ? true : false}
                                plan_expire={planExpired && planExpired.planExpire}
                                plan_description={"This plan is perfect for those needing large-scale, high-security storage solutions, ensuring that all your data is protected"}
                            />
                        </div>

                        {/* custom user selection plan */}
                        <CustomEnterprisePlan user_verify={verifyEmailandMobile && verifyEmailandMobile} did={did} wallet_address={walletAddress} />


                    </div>
                </div>}

            {/* plans conteainer  for 6 month*/}
            {planPeriod.month6 &&
                <div className='w-full flex bg-green-900/10 rounded-lg px-2'>
                    <div className='flex w-full  px-2 py-5 gap-5 flex-col'>
                        <div className='w-full flex lg:flex-row md:flex-col sm:flex-col min-[320px]:flex-col gap-5'>

                            <PricePlanCard plan_image={planImage} price={planPeriod.month6 ? 60 : ""} plan_name={"STARTER PLAN"} plan_space={1000} onBtnClick={addStaterPlan} currentPlan={currentPackage && currentPackage._packageName === "Starter" ? true : false}
                                plan_status={(planStatus.plan_name === "STARTER" && planStatus.is_adding === true) ? true : false}
                                plan_perioad={planPeriod}
                                plan_info={planInfo}
                                plan_status2={(planStatus.is_adding === true) ? true : false}
                                duration={planActivateDuration && planActivateDuration === 6 ? true : false}
                                plan_expire={planExpired && planExpired.planExpire}
                                plan_description={"Perfect for those managing large files or extensive data, ensuring your information is stored safely."}
                            />

                            <PricePlanCard plan_image={rocketImage} price={planPeriod.month6 ? 180 : ""} plan_name={"ADVANCE PLAN"} plan_space={5000} onBtnClick={addAdvancePlan} currentPlan={currentPackage && currentPackage._packageName === "Advance" ? true : false}
                                plan_status={(planStatus.plan_name === "ADVANCE" && planStatus.is_adding === true) ? true : false}
                                plan_info={planInfo}
                                plan_status2={(planStatus.is_adding === true) ? true : false}
                                duration={planActivateDuration && planActivateDuration === 6 ? true : false}
                                plan_perioad={planPeriod}
                                plan_expire={planExpired && planExpired.planExpire}
                                plan_description={"This plan is perfect for those needing large-scale, high-security storage solutions, ensuring that all your data is protected"}
                            />
                        </div>

                        {/* custom user selection plan */}
                        <CustomEnterprisePlan user_verify={verifyEmailandMobile && verifyEmailandMobile} did={did} wallet_address={walletAddress} />


                    </div>
                </div>}

            {/* plans conteainer  for 12 month*/}
            {planPeriod.year &&
                <div className='w-full flex bg-green-900/10 rounded-lg px-2'>
                    <div className='flex w-full  px-2 py-5 gap-5 flex-col'>
                        <div className='w-full flex lg:flex-row md:flex-col sm:flex-col min-[320px]:flex-col gap-5'>

                            <PricePlanCard plan_image={planImage} price={planPeriod.year ? 120 : ""} plan_name={"STARTER PLAN"} plan_space={1000} onBtnClick={addStaterPlan} currentPlan={currentPackage && currentPackage._packageName === "Starter" ? true : false}
                                plan_status={(planStatus.plan_name === "STARTER" && planStatus.is_adding === true) ? true : false}
                                plan_info={planInfo}
                                plan_status2={(planStatus.is_adding === true) ? true : false}
                                plan_perioad={planPeriod}
                                duration={planActivateDuration && planActivateDuration === 12 ? true : false}
                                plan_expire={planExpired && planExpired.planExpire}
                                plan_description={"Perfect for those managing large files or extensive data, ensuring your information is stored safely."}
                            />

                            <PricePlanCard plan_image={rocketImage} price={planPeriod.year ? 360 : ""} plan_name={"ADVANCE PLAN"} plan_space={5000} onBtnClick={addAdvancePlan} currentPlan={currentPackage && currentPackage._packageName === "Advance" ? true : false}
                                plan_status={(planStatus.plan_name === "ADVANCE" && planStatus.is_adding === true) ? true : false}
                                plan_info={planInfo}
                                plan_status2={(planStatus.is_adding === true) ? true : false}
                                plan_perioad={planPeriod}
                                duration={planActivateDuration && planActivateDuration === 12 ? true : false}
                                plan_expire={planExpired && planExpired.planExpire}
                                plan_description={"This plan is perfect for those needing large-scale, high-security storage solutions, ensuring that all your data is protected"}
                            />
                        </div>

                        {/* custom user selection plan */}
                        <CustomEnterprisePlan user_verify={verifyEmailandMobile && verifyEmailandMobile} did={did} wallet_address={walletAddress} />


                    </div>
                </div>}

            {/* sucess and fail msg */}

            {planStatus.compelte && (
                <div
                    className="fixed right-2 bottom-3 w-[250px] bg-black z-[200] border-bethel-green border-[1px]  h-[40px] rounded-sm
             py-2 flex-col flex popup items-center justify-center text-white"
                    id="popup"
                >
                    <div className="flex flex-row items-center justify-center gap-x-2">
                        <button className="rounded-full shadow-md">
                            <svg
                                viewBox="0 0 24 24"
                                fill="none"
                                className="w-5 "
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                    id="SVGRepo_tracerCarrier"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                    {" "}
                                    <path
                                        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                        stroke="#aaff00"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    ></path>{" "}
                                    <path
                                        d="M7.75 12L10.58 14.83L16.25 9.17004"
                                        stroke="#aaff00"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    ></path>{" "}
                                </g>
                            </svg>
                        </button>
                        <h1 className="text-sm font-sm text-bethel-green ">
                            Plan Added successfully
                        </h1>
                    </div>
                </div>
            )}

            {planStatus.fail && (
                <div
                    className="fixed right-2 bottom-3 w-[250px] bg-black z-[200] border-red-500 border-[1px]  h-[40px] rounded-sm
             py-2 flex-col flex popup items-center justify-center text-white animate-fadeInUpCustom"
                    id="popup"
                >
                    <div className="flex flex-row items-center justify-center gap-x-2">
                        <button className="rounded-full shadow-md">
                            <img src={iconClose} className="w-[25px]" alt="" />
                        </button>
                        <h1 className="text-sm font-sm text-red-500 ">
                            Plan Adding Fail
                        </h1>
                    </div>
                </div>
            )}

        </div>
    )
}
